import { dropRight } from "lodash";
import { useEffect, useMemo } from "react";
import styled from "styled-components";

import { checkmarkStyle, landingPageListFont } from "../../common-ui/styles";
import { sendViewItemEvent } from "../../services/googleTagManager";
import { PriceBlock } from "./PriceBlock";
import ProgramBuyButton from "./ProgramBuyButton";
import { Feature, ProgramLevelProps, ProgramLevelTitle } from "./ProgramLevel.types";
import {
  CollapsingDetails,
  Headline,
  ProgramImage,
  ProgramLevelCard,
  ProgramTitle,
  PurchaseButtonCaption,
  Whitespace,
} from "./programLevelComponents";

type ProgramFeatureProps = {
  faded?: boolean;
  isBlue?: boolean;
  bold?: boolean;
};

const ProgramFeature = styled.li<ProgramFeatureProps>`
  color: ${({ faded, isBlue }) =>
    faded ? "#999" : isBlue ? "#596dd3" : "#10181F"};
  margin-bottom: 5px;
  list-style: none;
  position: relative;
  margin-left: -2em;
  padding-left: 2em;
  ${landingPageListFont}
  ${checkmarkStyle}
`;

function extractFileName(path: string): string {
  const fileNameParts = dropRight(path.split("/").pop()?.split(".") || [], 1);
  return fileNameParts.join(".");
}

const renderFeature = (feature: Feature, i: number) => {
  const words = feature.text.split(" ");
  const firstWord = words.shift();
  const restOfWords = words.join(" ");

  return (
    <ProgramFeature key={i} isBlue={feature.isBlue} bold={feature.bold}>
      {feature.bold ? (
        <>
          <strong>{firstWord}</strong> {restOfWords}
        </>
      ) : (
        feature.text
      )}
    </ProgramFeature>
  );
};

const ProgramLevel = ({
  title,
  name,
  price: { current: price, original: originalPrice },
  headline,
  features,
  buyLink,
  image,
  mobileOrder,
  productId,
  listName,
  featureHeader,
  showLimitedSupply = true,
}: ProgramLevelProps) => {
  const hasDiscount = originalPrice > price;
  const showSupply = hasDiscount && showLimitedSupply;

  const itemEventData = useMemo(
    () => ({
      itemListName: listName,
      items: [
        {
          itemId: productId,
          originalPrice,
          price,
          itemName: name,
        },
      ],
    }),
    [productId, originalPrice, price, name, listName]
  );

  useEffect(() => {
    sendViewItemEvent(itemEventData);
  }, [itemEventData]);

  return (
    <ProgramLevelCard mobileOrder={mobileOrder}>
      {headline && (
        <Headline id={getProgramLevelCardId(title)} {...headline}>
          {headline.title}
        </Headline>
      )}
      <ProgramImage src={image} alt={extractFileName(image)} />
      <ProgramTitle>{title + (hasDiscount ? "*" : "")}</ProgramTitle>
      <PriceBlock
        current={price}
        original={originalPrice}
        hasLimitedSupply={showSupply}
      />
      {!showSupply ? <Whitespace>&nbsp;</Whitespace> : null}
      <ProgramBuyButton buyLink={buyLink} itemEventData={itemEventData} />
      <PurchaseButtonCaption>
        {featureHeader ? featureHeader : 'One-time payment for lifetime access'}
      </PurchaseButtonCaption>
      <CollapsingDetails>
        <ul>{features.map((feature, i) => renderFeature(feature, i))}</ul>
      </CollapsingDetails>
    </ProgramLevelCard>
  );
};

const getProgramLevelCardId = (title: ProgramLevelTitle) => `program-level-card-${title}`;

export type { Feature };

export { ProgramLevel, getProgramLevelCardId };

import { Dispatch, SetStateAction, useCallback, useEffect, useMemo, useState } from 'react';
import { StatsigProvider } from "statsig-react";
import showdown from 'showdown';
import styled from 'styled-components';
import { Model } from 'survey-core';
import { AfterRenderPageEvent, CompletingEvent, CurrentPageChangedEvent, TextMarkdownEvent } from 'survey-events-api';
import { Survey } from 'survey-react-ui';

import ProgressHeader from './features/ProgressHeader';
import { LandingPage } from './LandingPage';
import { COMPONENT_CONFIG, findPageIndex, getQuestionSection, getSectionInfo, isTransitionComponentKey, QuizSection, SURVEY_JSON } from './quiz';
import { Goals } from './quiz/goals';
import { sendChangesToGTM, sendPageViewEvent } from './services/googleTagManager';
import { submitUserToHubspot } from './services/hubspot';
import { User } from './types';

import './App.scss';
import { STATSIG_CLIENT_KEY } from './services/experiments';
import { getProgramNameForUser, LANDING_PAGE_CHOICES } from './features/programs/Program.config';
import Cookies from 'js-cookie';
import { CDN_ROOT } from './features/images';

/**
 * text headings: Oswald
 * text body: Lato
 * main: #FFFFFF
 * main: #406EDF
 * accent: #1E1E1E
 * accent: #DFB140
 */

// sorted somewhat in the order we need them
const IMAGE_PATHS = [
  'images/bodyfat/female35.jpg',
  'images/bodyfat/female20.jpg',
  'images/bodyfat/male40.jpg',
  'images/bodyfat/female30.jpg',
  'images/bodyfat/female18.jpg',
  'images/bodyfat/female25.jpg',
  'images/bodyfat/goalMale10.jpg',
  'images/bodyfat/male20.jpg',
  'images/bodyfat/male35.jpg',
  'images/bodyfat/female40.jpg',
  'images/bodyfat/goalMale12.jpg',
  'images/bodyfat/female45.jpg',
  'images/bodyfat/goalMale15.jpg',
  'images/bodyfat/male25.jpg',
  'images/bodyfat/male30.jpg',
  'images/bodyfat/male15.jpg',
  'images/bodyfat/male12.jpg',
  'images/bodyfat/goalMale20.jpg',
  'images/bodyfat/male10.jpg',
  'images/bodyfat/female15.jpg',
  'images/bodytype/Male/overweight.jpg',
  'images/bodytype/Male/skinny.png',
  'images/bodytype/Male/bulky.jpg',
  'images/bodytype/Male/skinnyfat.png',
  'images/bodytype/Female/overweight.jpg',
  'images/bodytype/Female/skinny.jpg',
  'images/bodytype/Female/bulky.png',
  'images/bodytype/Female/skinnyfat.jpg',
  'images/bws-loading-chart-2.jpg',
  'images/ktla.webp',
  'images/womensFitness.jpg',
  'images/splash/timeMale.jpg',
  'images/splash/protein.jpg',
  'images/splash/goodHands.jpg',
  'images/splash/timeFemale.jpg',
  'images/splash/habit.jpg',
  'images/splash/injuriesFemale.jpg',
  'images/splash/injuriesMale.jpg',
  'images/splash/busyLevel.jpg',
  'images/testimonials/brian.jpg',
  'images/testimonials/brian.png',
  'images/testimonials/jade.png',
  'images/testimonials/jade.jpg',
  'images/testimonials/cesar.png',
  'images/testimonials/cesar.jpg',
  'images/testimonials/katy.jpg',
  'images/testimonials/stephen.jpg',
  'images/testimonials/jesse.jpg',
  'images/testimonials/patrick.jpg',
  'images/testimonials/abdullah.jpg',
  'images/testimonials/salma.jpg',
  'images/testimonials/salma.png',
  'images/testimonials/ligia.jpg',
  'images/testimonials/adam.png',
  'images/testimonials/adam.jpg',
  'images/testimonials/reinis.jpg',
  'images/testimonials/tahnee.jpg',
  'images/testimonials/jan.jpg',
  'images/testimonials/jan.png',
  'images/youtube_social_icon_white.png',
  'images/TikTok_Icon_Black_Circle.png',
  'images/yahoo.jpg',
  'images/landingPage/FemaleStrongPremium.jpg',
  'images/landingPage/MaleBuildPremium.jpg',
  'images/landingPage/FemaleStrongBase.jpg',
  'images/landingPage/FemaleLeanElite.jpg',
  'images/landingPage/MaleBuildElite.jpg',
  'images/landingPage/MaleShredElite.jpg',
  'images/landingPage/FemaleLeanBase.jpg',
  'images/landingPage/MaleBuildBase.jpg',
  'images/landingPage/MaleShredPremium.jpg',
  'images/landingPage/FemaleLeanPremium.jpg',
  'images/landingPage/MaleShredBase.jpg',
  'images/landingPage/FemaleStrongElite.jpg',
  'images/f_logo_RGB-Blue_144.png',
  'images/mensHealth.jpg',
  'images/bws-loading-chart.jpg',
];

const AppContainer = styled.div`
  font-family: 'Lato', sans-serif;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  &:has(.quote-container) {
    background-color: #add8e6;
  }
`;

const HeaderContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
`;

const useImagePreloader = () => {
  useEffect(() => {
    const preloadImages = () => {
      const promises = IMAGE_PATHS.map((path) => {
        return new Promise((resolve, reject) => {
          const img = new Image();
          img.onload = resolve;
          img.onerror = reject;
          img.src = CDN_ROOT + '/' + path;
        });
      });

      Promise.all(promises).catch((error) => {
        console.error("Error preloading images:", error);
      });
    };
    preloadImages();
  }, []);
};

type TransitionComponentProps<T> = {
  user: T;
  onComplete: () => void;
}

const markdownToHtmlFuncGenerator = (converter: showdown.Converter) => (_: Model, options: TextMarkdownEvent) => {
  const { element, name, text } = options;
  const isSurveyPartToUpdate = ['title', 'description'].includes(name);
  const isSurveyType = element.getType() !== 'survey';
  const isExperienceElement = element.name === 'experience';
  const isTextName = name === 'text';

  if ((isSurveyPartToUpdate && isSurveyType) || (isExperienceElement && isTextName)) {
    const htmlStr = converter.makeHtml(text);
    const startTag = '<p>';
    const endTag = '</p>';
    const startTagIndex = htmlStr.indexOf(startTag);
    const endTagIndex = htmlStr.lastIndexOf(endTag);

    options.html = (startTagIndex !== -1 && endTagIndex !== -1)
      ? htmlStr.slice(startTagIndex + startTag.length, endTagIndex)
      : htmlStr;
  }
};

const onAfterRenderPageFuncGenerator = (setPageNo: Dispatch<SetStateAction<number>>, setPageTotal: Dispatch<SetStateAction<number>>, setTitle: Dispatch<SetStateAction<QuizSection>>) => (_: Model, options: AfterRenderPageEvent) => {
  const questionName = options.page.name;
  const section = getQuestionSection(questionName);
  if (!!section) {
    const sectionInfo = getSectionInfo(section);
    setTitle(section);
    setPageTotal(sectionInfo.total);
    setPageNo(findPageIndex(questionName) - sectionInfo.startIndex + 1);
  } else {
    if (process.env.NODE_ENV === 'development') {
      console.error(`Missing section configuration for question: ${questionName}`); // TODO send errors to some logging service
    }
    setPageNo(page => page + 1);
  }
};

const onCurrentPageChangedFuncGenerator = (config: QuizTransitionConfig, setTransitionComponentKey: Dispatch<SetStateAction<string | null>>, setUser: Dispatch<SetStateAction<User>>) => (sender: Model, options: CurrentPageChangedEvent) => {
  const { oldCurrentPage, newCurrentPage } = options;
  const transitionConfig = config[oldCurrentPage.name];
  if (transitionConfig && (!transitionConfig.nextPage || transitionConfig.nextPage === newCurrentPage.name)) {
    setTransitionComponentKey(oldCurrentPage.name);
  }
  sendChangesToGTM(sender, oldCurrentPage, findPageIndex(oldCurrentPage.name) + 1);
  newCurrentPage.questions.forEach(question => {
    question.clearValue();
    if (question.defaultValue != null) {
      question.value = question.defaultValue;
    }
  });
  if (oldCurrentPage.name === 'email') { // we only want to submit the user once they have entered their email
    submitUserToHubspot(sender.data, false);
  }
  setUser(user => ({
    ...user,
    [oldCurrentPage.name]: sender.data[oldCurrentPage.name],
    ...(oldCurrentPage.name === 'weight' ? {
      weightUnit: sender.data.weightUnit,
    } : {}),
    ...(oldCurrentPage.name === 'height' ? {
      heightUnit: sender.data.heightUnit,
      heightRaw: sender.data.heightRaw,
      heightInches: sender.data.heightInches,
    } : {}),
  }));
};

const onCompletingFuncGenerator = (setTransitionComponentKey: Dispatch<SetStateAction<string | null>>, setUser: Dispatch<SetStateAction<User>>) => (sender: Model, _: CompletingEvent) => {
  const pageName: string = sender.currentPage.name;
  setTransitionComponentKey(pageName);
  sendChangesToGTM(sender, sender.currentPage, findPageIndex(sender.currentPage.name) + 1);
  setUser(user => ({
    ...user,
    [pageName]: sender.data[pageName],
  }));
  if (isTransitionComponentKey(pageName)) {
    const goal = sender.data.goals as Goals;
    sendPageViewEvent(goal, COMPONENT_CONFIG[pageName].componentName, getProgramNameForUser(sender.data), LANDING_PAGE_CHOICES, findPageIndex(COMPONENT_CONFIG[pageName].componentName) + 1);
    submitUserToHubspot(sender.data, true);
  }
};

type QuizTransitionConfig = {
  [key: string]: {
    nextPage?: string,
    component?: (props: TransitionComponentProps<User>) => JSX.Element,
  }
}

function useSurvey(surveyJson: Object, converter: showdown.Converter): [Model, number, ((props: TransitionComponentProps<User>) => JSX.Element) | undefined, string | undefined, Dispatch<SetStateAction<string | null>>, QuizSection, User] {
  const [pageNo, setPageNo] = useState(0);
  const [pageTotal, setPageTotal] = useState(1);
  const [section, setSection] = useState<QuizSection>(QuizSection.GETTING_STARTED);
  const [user, setUser] = useState<User>({} as User);
  const [transitionComponentKey, setTransitionComponentKey] = useState<string | null>(null);
  const transitionComponent = useMemo(() => {
    if (isTransitionComponentKey(transitionComponentKey)) {
      return COMPONENT_CONFIG[transitionComponentKey].component;
    }
  }, [transitionComponentKey]);
  const transitionComponentName = useMemo(() => {
    if (isTransitionComponentKey(transitionComponentKey)) {
      return COMPONENT_CONFIG[transitionComponentKey].componentName;
    }
  }, [transitionComponentKey]);

  const survey = useMemo(() => new Model(surveyJson), [surveyJson]);
  const onAfterRenderPageHandler = useMemo(() => onAfterRenderPageFuncGenerator(setPageNo, setPageTotal, setSection), [setPageNo, setPageTotal, setSection]);
  const markdownToHtmlConverter = useMemo(() => markdownToHtmlFuncGenerator(converter), [converter]);
  const onCurrentPageChangedHandler = useMemo(() => onCurrentPageChangedFuncGenerator(COMPONENT_CONFIG, setTransitionComponentKey, setUser), [setTransitionComponentKey, setUser]);
  const onCompletingHandler = useMemo(() => onCompletingFuncGenerator(setTransitionComponentKey, setUser), [setTransitionComponentKey, setUser]);

  useEffect(() => {
    survey.sendResultOnPageNext = true;
    survey.onTextMarkdown.add(markdownToHtmlConverter);
    survey.onAfterRenderPage.add(onAfterRenderPageHandler);
    survey.onCurrentPageChanged.add(onCurrentPageChangedHandler);
    survey.onCompleting.add(onCompletingHandler);
    return () => {
      survey.onTextMarkdown.remove(markdownToHtmlConverter);
      survey.onAfterRenderPage.remove(onAfterRenderPageHandler)
      survey.onCurrentPageChanged.remove(onCurrentPageChangedHandler);
      survey.onCompleting.remove(onCompletingHandler);
    }
  }, [markdownToHtmlConverter, onAfterRenderPageHandler, onCurrentPageChangedHandler, onCompletingHandler, survey]);

  const progressPercent = Math.ceil((100 * pageNo) / pageTotal);
  return [survey, progressPercent, transitionComponent, transitionComponentName, setTransitionComponentKey, section, user];
}

function App() {
  var converter = new showdown.Converter();
  useImagePreloader();
  // const questions = SURVEY_JSON.pages.map(page => ({ name: page.name, title: page.elements[0].title }));
  // // take that survey json and convert it to a csv

  // return <>
  //   <div>
  //     {questions.map(question => <div>{question.name + ', ' + question.title}</div>)}
  //   </div>
  // </>; 
  const [
    survey,
    progressPercent,
    TransitionComponent,
    transitionComponentName,
    setTransitionComponentKey,
    progressTitle,
    user] = useSurvey(SURVEY_JSON, converter);

  const onComplete = useCallback(() => {
    if (transitionComponentName != null) {
      sendPageViewEvent(user.goals, transitionComponentName, '', [], findPageIndex(transitionComponentName) + 1);
    }
    setTransitionComponentKey(null);
  }, [setTransitionComponentKey, transitionComponentName, user.goals]);

  const overrideStableID = useMemo(() => Cookies.get('bws_statsig_stable_id'), []);

  return (
    <StatsigProvider
      sdkKey={STATSIG_CLIENT_KEY}
      user={{
        privateAttributes: {
          email: user.email,
        }
      }}
      options={{
        overrideStableID,
        environment: {
          tier: process.env.NODE_ENV,
        }
      }}
      waitForInitialization={true}
    >
      <AppContainer>
        {progressTitle && TransitionComponent !== LandingPage ? <HeaderContainer>
          <ProgressHeader title={progressTitle} progress={progressPercent} />
        </HeaderContainer> : <></>}
        {
          TransitionComponent == null
            ? <Survey model={survey} />
            : <TransitionComponent user={user} onComplete={onComplete} />
        }
      </AppContainer>
    </StatsigProvider>
  );
}

export default App;

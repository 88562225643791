import { useMemo } from "react";
import styled from "styled-components";

import { Text } from "../../common-ui/Text";
import { ProgramPrice, Whitespace } from "./programLevelComponents";

const OGPrice = styled.span`
  font-family: "Oswald", sans-serif;
  font-size: 30px;
  text-decoration: line-through;
  color: #98a1a8;
  margin-bottom: 10px;
`;

const SavingsValue = styled.span``;
const SavingsContainer = styled.span`
  font-weight: 700;
  ${SavingsValue} {
    color: red;
  }
`;

const WeeklyPrice = styled.span`
  color: red;
  font-style: italic;
`;

const PriceTextBlock = styled(Text)`
  margin-top: 5px;
  margin-bottom: 5px;
`;

const LimitedTimeText = styled.p`
  font-style: italic;
  color: #98a1a8;
  margin-top: 0px;
  margin-bottom: 0px;
`;

const savingsPercent = (originalPrice: number, price: number) => {
  return ((100 * (originalPrice - price)) / originalPrice).toPrecision(2);
};

type PriceBlockProps = {
  current: number;
  original: number;
  hasLimitedSupply: boolean;
  durationWeeks?: number | null;
};

const PriceBlock = (props: PriceBlockProps) => {
  const { current, original, hasLimitedSupply, durationWeeks } = props;
  const savings = useMemo(() => original - current, [original, current]);
  const percent = useMemo(
    () => savingsPercent(original, current),
    [original, current]
  );
  const weekly = useMemo(
    () => (durationWeeks ? current / durationWeeks : null),
    [current, durationWeeks]
  );
  const hasDiscount = savings > 0;

  return (
    <>
      <ProgramPrice>
        ${current} <OGPrice> {hasDiscount ? `$${original}` : ""}</OGPrice>
      </ProgramPrice>
      <PriceTextBlock>
        {/* <Whitespace>&nbsp;</Whitespace> */}
        {hasDiscount ? (
          <>
            <SavingsContainer>
              You save: <SavingsValue>${savings}.00 ({percent}%)</SavingsValue>
              <br />
            </SavingsContainer>
            {hasLimitedSupply ? (
              <LimitedTimeText>*limited supply only</LimitedTimeText>
            ) : null}
          </>
        ) : (
          <>
            <Whitespace>&nbsp;</Whitespace>
          </>
        )}

        {weekly && (
          <>
            <WeeklyPrice>
              {/* <br /> */}
              Equals to ${weekly.toFixed(2)}/week
            </WeeklyPrice>
          </>
        )}
      </PriceTextBlock>
    </>
  );
};

export type { PriceBlockProps };

export { PriceBlock };

import React from "react";
import ReactDOM from "react-dom/client";
import Statsig from "statsig-js";
import "survey-core/defaultV2.min.css";
import "./index.css";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import { Amplify } from "aws-amplify";
// @ts-ignore
import awsmobile from "./aws-exports";
import {
  FunnelVariation,
  QuizEventData,
  pushToDataLayer,
} from "./services/googleTagManager";
import {
  STATSIG_CLIENT_KEY,
  checkStripeGate,
  getVersionNumber,
} from "./services/experiments";
import Cookies from "js-cookie";
import * as amplitude from "@amplitude/analytics-browser";
// @ts-ignore
import { v4 as uuidv4 } from "uuid";

const AMPLITUDE_API_KEY = process.env.REACT_APP_AMPLITUDE_API_KEY || "";

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
);

Amplify.configure(awsmobile);

Statsig.initialize(
  STATSIG_CLIENT_KEY,
  {},
  {
    overrideStableID: Cookies.get("bws_statsig_stable_id"),
    environment: {
      tier: process.env.NODE_ENV,
    },
  }
).then(() => {
  Cookies.set("bws_statsig_stable_id", Statsig.getStableID(), {
    expires: 365,
    sameSite: "lax",
    domain:
      process.env.NODE_ENV === "development"
        ? "localhost"
        : "builtwithscience.com",
  });
  const quizEventData: QuizEventData = {
    event: "quiz",
    funnel_variation: FunnelVariation.General,
    quizz_name: "New Onboarding Quiz",
    quizz_step: "0",
    quizz_step_name: "Start",
    quizz_version: getVersionNumber(),
  };
  pushToDataLayer(quizEventData);
  Statsig.logEvent("quiz", "0", {
    funnel_variation: FunnelVariation.General,
    step_name: "Start",
  });
  if (checkStripeGate()) {
    const amplitudeDeviceId = uuidv4();
    Cookies.set("bws_amplitude_device_id", amplitudeDeviceId, {
      expires: 365,
      sameSite: "lax",
      domain:
        process.env.NODE_ENV === "development"
          ? "localhost"
          : "builtwithscience.com",
    });
    amplitude.init(AMPLITUDE_API_KEY, undefined, {
      deviceId: amplitudeDeviceId,
    });
    amplitude.track("quiz", {
      step: "0",
      step_name: "Start",
      funnel_variation: FunnelVariation.General,
    });
  }
});

root.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();

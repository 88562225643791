import { useEffect, useMemo } from "react";
import { FourMonthProgramLevelProps } from "./FourMonthProgramLevel.types";
import { sendViewItemEvent } from "../../services/googleTagManager";
import {
  BonusProgramFeature,
  CollapsingDetails,
  Headline,
  ProgramFeature,
  ProgramImage,
  ProgramLevelCard,
  ProgramTitle,
  PurchaseButtonCaption,
  Whitespace,
  extractFileName,
} from "./programLevelComponents";
import { PriceBlock } from "./PriceBlock";
import ProgramBuyButton from "./ProgramBuyButton";
import Markdown from "react-markdown";
import { ProgramLevelTitle } from "./ProgramLevel.types";

const FourMonthProgramLevel = (props: FourMonthProgramLevelProps) => {
  const {
    price,
    productId,
    listName,
    name,
    image,
    mobileOrder,
    headline,
    title,
    buyLink,
    guaranteeDurationDays,
    features,
  } = props;
  const hasDiscount = price.original > price.current;

  const itemEventData = useMemo(
    () => ({
      itemListName: listName,
      items: [
        {
          itemId: productId,
          originalPrice: price.original,
          price: price.current,
          itemName: name,
        },
      ],
    }),
    [price, productId, name, listName]
  );

  useEffect(() => {
    sendViewItemEvent(itemEventData);
  });

  return (
    <ProgramLevelCard mobileOrder={mobileOrder}>
      <ProgramImage src={image} alt={extractFileName(image)} />
      {headline && (
        <Headline id={getFourMonthProgramLevelCardId(title)} {...headline}>
          {headline.title}
        </Headline>
      )}
      <ProgramTitle>{title + (hasDiscount ? "*" : "")}</ProgramTitle>
      <PriceBlock
        current={price.current}
        original={price.original}
        hasLimitedSupply={hasDiscount}
      />
      {!hasDiscount ? <Whitespace>&nbsp;</Whitespace> : null}
      <ProgramBuyButton
        buyLink={buyLink}
        itemEventData={itemEventData}
        text={"START FREE TRIAL"}
      />
      <PurchaseButtonCaption>
        {guaranteeDurationDays} day money-back guarantee
      </PurchaseButtonCaption>
      <CollapsingDetails>
        <ul>
          {features.basic.map((item, i) => (
            <ProgramFeature key={i}>
              <Markdown>{item}</Markdown>
            </ProgramFeature>
          ))}
          {features.bonus.map((item, i) => (
            <BonusProgramFeature key={i}>
              <Markdown>{item}</Markdown>
            </BonusProgramFeature>
          ))}
        </ul>
      </CollapsingDetails>
    </ProgramLevelCard>
  );
};

const getFourMonthProgramLevelCardId = (title: ProgramLevelTitle) =>
  `fourmonth-program-level-${title}`;

export { FourMonthProgramLevel, getFourMonthProgramLevelCardId };

// import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
// import { faFacebookSquare, faYoutube, faInstagram } from "@fortawesome/free-brands-svg-icons";
import styled from "styled-components";

const FooterContainer = styled.footer`
  background: #282828;
`;

const InnerWrap = styled.div`
  height: 100%;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 30px 20px;
  max-width: 1450px;
`;

const Nav = styled.nav`
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  margin-bottom: 10px;
  color: #fff;

  @media (max-width: 1120px) {
    display: grid;
    grid-template-columns: repeat(1, minmax(200px, 1fr));
    gap: 0;
  }
`;

const NavLink = styled.a`
  text-decoration: none;
  background-color: transparent;
  color: #fff;
  padding: 13px 20px;
  transition-duration: 0.4s;
  font-weight: 400;
  text-transform: uppercase;
  font-size: 15px;
  font-family: "Lato";

  &:hover {
    color: #f4db60;
  }

  &:active {
    border-bottom-style: solid;
    border-bottom-width: 2px;
    border-color: #fff;
  }
`;

// const SocialIconLink = styled.a`
//   text-decoration: none;
//   background-color: transparent;
//   color: #0074db;
//   display: flex;
//   align-items: center;
//   justify-content: center;

//   &:hover {
//     color: #10181f;
//     transform: scale(1.05);
//   }
// `;

// const SocialIcon = styled.div`
//   border-radius: 50%;
//   color: #1e376c;
//   padding: 8px;
//   border: 1px solid #fff;
//   background-color: #fff;
//   transition-duration: 0.5s;
//   margin: 0 10px;

//   &:hover {
//     color: #10181f;
//     border-color: #f4db60; 
//     background-color: #f4db60;
//     transform: scale(1.05);
//   }
// `;

// const SocialIconSVG = styled(FontAwesomeIcon)`
//   fill: currentcolor;
//   height: 30px;
//   width: 30px;
// `;

const TextBlock = styled.div`
  margin: 20px 0;
  font-size: 12px;
  font-family: "Lato";
  color: #fff;
`;

const Footer = (): JSX.Element => {
  return (
    <FooterContainer>
      <InnerWrap>
        <Nav>
          <NavLink href="https://builtwithscience.com/" target="_self">
            home
          </NavLink>
          <NavLink href="https://builtwithscience.com/fitness-and-nutrition-coaching">2-on-1 coaching</NavLink>
          <NavLink href="https://builtwithscience.com/blog-post/" target="_self">
            blog
          </NavLink>
          <NavLink href="https://builtwithscience.com/careers/" target="_self">
            careers
          </NavLink>
          <NavLink href="https://builtwithscience.com/disclaimer/" target="_self">
            disclaimer
          </NavLink>
          <NavLink href="https://builtwithscience.com/privacy-policy/" target="_self">
            privacy policy
          </NavLink>
          <NavLink href="https://builtwithscience.com/terms-of-use/" target="_self">
            terms of use
          </NavLink>
        </Nav>
        {/* <Nav>
          <SocialIconLink href="https://www.facebook.com/Jeremyethierfit" target="_self">
            <SocialIcon>
              <SocialIconSVG icon={faFacebookSquare} />
            </SocialIcon>
          </SocialIconLink>
          <SocialIconLink href="https://www.youtube.com/jeremyethier" target="_self">
            <SocialIcon>
              <SocialIconSVG icon={faYoutube} />
            </SocialIcon>
          </SocialIconLink>
          <SocialIconLink href="https://www.instagram.com/jeremyethier/" target="_self">
            <SocialIcon>
              <SocialIconSVG icon={faInstagram} />
            </SocialIcon>
          </SocialIconLink>
        </Nav> */}
        <TextBlock>
          Please Note: The author of this site is not engaged in rendering professional advice or services to the
          individual reader. The ideas, procedures, and suggestions contained within this work are not intended as a
          substitute for consulting with your physician. All matters regarding your health require medical supervision.
          The author shall not be liable or responsible for any loss or damage allegedly arising from any information or
          suggestions within this blog. You, as a reader of this website, are totally and completely responsible for your
          own health and healthcare. Your results may vary. Testimonials and examples used are exceptional results and are
          not intended to guarantee, promise, represent and/or assure that anyone will achieve the same or similar results.
          <br />
          Built With Science® may not be copied or used for any purpose without express written consent.
        </TextBlock>
      </InnerWrap>
    </FooterContainer>
  );
};

export { Footer };
